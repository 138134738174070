<template>
  <div class="rule">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="el-nav">
      <el-tab-pane :label="'直播管理'" name="live"> </el-tab-pane>
      <el-tab-pane :label="'直播详情'" name="liveShow" v-loading="loading">
        <div v-if="info.type === 'meeting'">
          <div class="live_show">
            <div class="left">
              <img :src="info.cover_url" alt="" />
              <div v-if="info.live_status === 2" class="tags not_started">未开始</div>
              <div v-if="info.live_status === 1" class="tags live_broadcast">直播中</div>
              <div v-if="info.live_status === 3" class="tags replay_live">回放</div>
              <div v-if="info.live_status === 4" class="tags be_reviewed">待审核</div>
            </div>
            <div class="right">
              <div class="me_msg">
                <p class="title">{{ info.title }}</p>
                <div class="tx_tag">腾讯会议</div>
              </div>

              <p class="sponsor" v-if="info.organizer !== ''">主办方：{{ info.organizer }}</p>
              <p class="time">会议时间：{{ info.start_time }} - {{ info.end_time }}</p>
              <p class="num">
                参会人：{{ info.participants_num }}人 | 观看人：{{ info.watch_num }}人 | 点赞量：{{ info.good_count > 9999 ? (info.good_count / 10000).toFixed(2) + 'W' : info.good_count }}
              </p>
            </div>
          </div>
          <div class="path">
            <p class="link">邀请主持人：{{ info.audience_join_link }}</p>
            <el-button type="text" class="total-right vertical-center" @click="delImg(info.audience_join_link)">
              <img src="~assets/images/index/copy.png" alt="" style="vertical-align: bottom" class="del" />
              <img src="~assets/images/index/copy-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
              <span style="margin-left: 8px">复制链接</span>
            </el-button>
          </div>
          <div class="path">
            <p class="link">主持密钥：{{ info.host_key }}</p>
            <el-button type="text" class="total-right vertical-center" @click="delImg(info.host_key)">
              <img src="~assets/images/index/copy.png" alt="" style="vertical-align: bottom" class="del" />
              <img src="~assets/images/index/copy-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
              <span style="margin-left: 8px">复制链接</span>
            </el-button>
          </div>
        </div>
        <div v-if="info.type !== 'meeting'">
          <div class="live_show">
            <div class="left_other">
              <img v-if="isPlay" class="cover_url" :src="info.cover_url" alt="" />
              <img v-if="isPlay && info.live_status === 2" class="play-btn" src="~assets/images/nav/play.png" alt="" @click="palyLive" />
              <video v-if="info.live_status === 1 || info.live_status === 2" :id="player_id" :width="160" :height="270" preload="auto" playsinline webkit-playsinline></video>
            </div>
            <div class="right_other">
              <div class="me_top">
                <div class="me_msg">
                  <p class="title">{{ info.title }}</p>
                  <div class="tx_tag">其他平台</div>
                </div>

                <p class="sponsor" v-if="info.organizer !== ''">主办方：{{ info.organizer }}</p>
                <p class="time">会议时间：{{ info.start_time }} - {{ info.end_time }}</p>
                <p class="num">观看人：{{ info.watch_num }}人 | 点赞量：{{ info.good_count > 9999 ? (info.good_count / 10000).toFixed(2) + 'W' : info.good_count }}</p>
              </div>

              <div>
                <div class="path path_other">
                  <p class="link link_other">推流地址：{{ info.push_address }}</p>
                  <el-button type="text" class="total-right vertical-center" @click="delImg(info.push_address)">
                    <img src="~assets/images/index/copy.png" alt="" style="vertical-align: bottom" class="del" />
                    <img src="~assets/images/index/copy-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
                    <span style="margin-left: 8px">复制链接</span>
                  </el-button>
                </div>
                <div class="path path_other">
                  <p class="link link_other">推流秘钥：{{ info.push_key }}</p>
                  <el-button type="text" class="total-right vertical-center" @click="delImg(info.push_key)">
                    <img src="~assets/images/index/copy.png" alt="" style="vertical-align: bottom" class="del" />
                    <img src="~assets/images/index/copy-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
                    <span style="margin-left: 8px">复制链接</span>
                  </el-button>
                </div>
              </div>
              <el-button class="live-btn" :class="!isDisabled ? 'active_btn' : 'bo_btn'" type="primary" @click="startLive(info)" :disabled="isDisabled">{{
                info.live_status === 2 ? '开始直播' : info.live_status === 1 ? '结束直播' : info.live_status === 4 ? '待审核' : '回放'
              }}</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--回放列表-->
    <div class="replay" v-if="info.live_status === 3">
      <div class="total space-between vertical-center">
        <div class="total-left" v-loading="loading">
          回放列表<span class="total-left-text1">
            （<span class="total-left-text">{{ tableList.length }}</span
            >条）</span
          >
        </div>

        <div class="total-right">
          <el-button type="primary" class="search-btn" @click="editReplay">上传回放</el-button>
        </div>
      </div>
      <div class="replay_list" v-if="tableList.length !== 0">
        <div class="item" v-for="(item, index) in renderList" :key="index" :class="(index + 1) % 4 === 0 ? 'last' : ''">
          <div class="cover">
            <div class="mask"></div>
            <div class="tag" :class="item.status === 1 ? 'show' : 'heddin'">{{ item.status === 1 ? '发布' : '关闭' }}</div>
            <img class="cover_url" :src="info.cover_url" alt="" />
            <img class="play" src="~assets/images/nav/play.png" alt="" @click="palyVideo(item, index)" />
          </div>
          <div class="replay_footer">
            <p class="fragment">{{ item.name ? item.name : `回放片段 ${index + 1}` }}</p>
            <el-dropdown @command="contentStatus($event, item, index)">
              <span class="el-dropdown-link">
                <img src="~assets/images/index/mores.png" class="moresimg" alt="" style="vertical-align: middle" />
                <img src="~assets/images/index/mores-h.png" class="moresimgh" alt="" style="vertical-align: middle" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">下载</el-dropdown-item>
                <el-dropdown-item command="2">编辑</el-dropdown-item>
                <el-dropdown-item command="3" v-if="item.status === -1">显示</el-dropdown-item>
                <el-dropdown-item command="4" v-if="item.status === 1">隐藏</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <empty-prompt class="empty" v-else></empty-prompt>
      <div class="footer" @click="listType" v-if="tableList.length > 4">
        <span>{{ open ? '收起列表' : '展开列表' }}</span>
        <img v-if="!open" class="no-act" src="~assets/images/index/bottom.png" alt="" />
        <img v-if="!open" class="act" src="~assets/images/index/bottom-act.png" alt="" />
        <img v-if="open" class="no-act" src="~assets/images/index/top.png" alt="" />
        <img v-if="open" class="act" src="~assets/images/index/top-act.png" alt="" />
      </div>
    </div>
    <!--评论列表-->
    <div class="comment-footer" v-loading="loading">
      <div class="total space-between vertical-center">
        <div class="total-left">
          全部评论
          <span class="total-left-text1">
            （<span class="total-left-text">{{ commentNum }}</span
            >条）</span
          >
        </div>
        <el-button type="text" class="total-right vertical-center" @click="openPage" v-if="info.live_status === 1">
          <img src="~assets/images/index/bullet.png" alt="" style="vertical-align: bottom" class="del" />
          <img src="~assets/images/index/bullet-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
          <span style="margin-left: 8px">查看弹幕</span>
        </el-button>
      </div>
      <div class="comment-list-div">
        <div class="comments_list">
          <div class="comments_item" v-for="(item, index) in comment" :key="index">
            <div class="user_info">
              <div class="user_left">
                <img :src="item.user.avatar" alt="" /> <span class="name">{{ item.user.nickname }}</span>
              </div>
              <div class="right_time">{{ item.format_msg_time }}</div>
            </div>
            <div class="contents">
              <span v-for="(msg, inx) in item.msg_body" :key="inx">{{ msgContent(msg) }}</span>
            </div>
          </div>
        </div>
        <div class="" v-if="comment && comment.length">
          <div class="align-center load" @click="commentMore()" v-if="comment.length !== commentNum">
            <img src="~assets/images/index/more.png" alt="" />
            <span class="loadmore">加载更多</span>
          </div>
          <div class="align-center" v-else>
            <span class="loadmore">暂无更多</span>
          </div>
        </div>
        <empty-prompt style="margin-top: 100px" v-else></empty-prompt>
      </div>
    </div>
    <!--视频弹框-->
    <el-dialog class="dzdialog" :title="videoTitle" :visible.sync="isvideoDialogVisible" top="20vh" width="840px" v-if="isvideoDialogVisible" :before-close="handleCloseVideo">
      <play-video :file_id="media_id" class="play-video"></play-video>
    </el-dialog>
    <!--编辑视频-->
    <upload-video ref="video" :config="videoConfig" @uploadStatus="uploadStatus"></upload-video>

    <!-- 隐藏弹框-->
    <el-dialog
      class="delDiolog"
      :visible.sync="isdelDialogVisible"
      top="calc( 50vh - 150px )"
      icon="el-icon-warning"
      :destroy-on-close="true"
      width="360px"
      center
      :close-on-click-modal="false"
      @close="closeDiog"
    >
      <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
      <span>你确定{{ showOrHiden }}该回放么？</span>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="closeDiog">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="putTagStatus">确 认</el-button>
      </span>
    </el-dialog>
    <!--结束直播-->
    <el-dialog
      class="delDiolog"
      :visible.sync="isStopDialogVisible"
      top="calc( 50vh - 150px )"
      icon="el-icon-warning"
      :destroy-on-close="true"
      width="360px"
      center
      :close-on-click-modal="false"
      @close="closeStopDiog"
    >
      <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
      <span>你确定要结束该直播吗？</span>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="closeStopDiog">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="cifStopLive">确 认</el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="dialog"
      :title="dialogTitle"
      :visible.sync="dialogFormReplay"
      v-if="dialogFormReplay"
      top="calc( 50vh - 250px )"
      width="540px"
      :destroy-on-close="true"
      @close="closeReplay('from')"
      :close-on-click-modal="false"
    >
      <el-form label-position="left" :label-position="labelPosition" label-width="93px" class="form" ref="from" :rules="rules" :model="form">
        <el-form-item label="上个视频" prop="pid" v-if="isFirst">
          <el-select v-model="form.pid" placeholder="请选择">
            <el-option v-for="(item, index) in replayList" :key="item.id" :label="item.name ? item.name : `回放片段${index + 1}`" :value="item.id"> </el-option>
          </el-select>
          <p class="tips" v-if="this.video_id === ''">注：新上传视频排在该视频之后，若未选择默认排在最后</p>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model.trim="form.name" placeholder="请输入名称" maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="视频" prop="address" class="is-required">
          <el-button class="add-video-btn" type="primary" @click="addVideo">+上传视频 </el-button>
        </el-form-item>
        <el-form-item v-if="AddVideoTitle">
          <el-input v-model="AddVideoTitle" disabled></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="closeReplay('from')">取 消</el-button>
        <el-button v-if="isClick" class="form-operation-item" round type="primary" @click="onReplay('from')">保存 </el-button>
        <el-button v-else class="form-operation-item" round type="primary">保存 <i class="el-icon-loading"></i></el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PlayVideo from 'components/playVideo';
import EmptyPrompt from 'components/EmptyPrompt';
import uploadVideo from '../components/video';
export default {
  props: {},
  data() {
    return {
      isFirst: true,
      isAdd: true,
      AddVideoTitle: '',
      labelPosition: 'right',
      dialogTitle: '新增回放',
      videoConfig: {},
      loading: false,
      isdelDialogVisible: false,
      isStopDialogVisible: false,
      showOrHiden: '',
      isPlay: true,
      videoTitle: '',
      media_id: '',
      isvideoDialogVisible: false,
      comDialogVisible: false,
      open: false,
      activeName: 'liveShow',
      uuid: '',
      id: '',
      info: {},
      tableList: [],
      renderList: [],
      replayList: [],
      comment: [],
      commentNum: 0,
      page: 1,
      pageSize: 20,
      video_uuid: '',
      tim: '',
      timer: null,
      player: '',
      playback: [],
      video_id: '',
      player_id: '',
      isDisabled: false,
      dialogFormReplay: false,
      isClick: true,
      form: {
        address: '',
        name: '',
        pid: '',
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        address: [{ required: true, message: '请上传视频', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.id = this.$route.params.uuid;
  },
  mounted() {
    this.getDetail();
    this.getNews();
    this.getReplay();
    this.player_id = `player-container-id_${new Date().getTime()}`;
  },

  methods: {
    // 取消新增回放
    closeReplay(formName) {
      this.dialogFormReplay = false;
      this.AddVideoTitle = '';
      this.video_id = '';
      this.$refs[formName].resetFields();
      this.form.address = '';
      this.form.name = '';
      this.form.pid = '';
      this.isFirst = true;
    },

    // 新增提交
    onReplay(formName) {
      this.$refs[formName].validate((valid) => {
        let url = '';
        if (valid) {
          this.isClick = false;
          if (this.isAdd) {
            this.form.live_conference_id = this.id;
            url = `${this.$api.playback}/${this.$api.address}`;
          } else {
            url = `${this.$api.playback}/${this.video_id}/${this.$api.address}`;
          }

          this.$http.post(url, this.form, true).then((res) => {
            console.log(res);
            if (res.data.success) {
              this.$message({
                message: '操作成功',
                type: 'success',
              });
              this.isClick = true;
              this.closeReplay(formName);
              this.getDetail();
              this.getReplay();
            }
          });
        }
      });
    },

    // 上传视频
    addVideo() {
      this.videoConfig = {
        uuid: this.id,
        type: 'live_playback',
        limit_num: 1,
      };
      console.log(this.videoConfig);
      setTimeout(() => {
        this.$refs.video.addMedia();
      }, 200);
    },

    // 回放
    editReplay() {
      this.isAdd = true;
      this.dialogFormReplay = true;
      this.dialogTitle = '新增回放';
    },

    // 关闭结束确认弹框
    closeStopDiog() {
      this.isStopDialogVisible = false;
    },

    // 回放列表
    getReplay() {
      let url = `${this.$api.playback}/${this.$api.address}?live_conference_id=${this.id}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.replayList = res.data.data;
        }
        console.log(res);
      });
    },

    // 结束确认
    cifStopLive() {
      let url = `${this.$api.meeting}/${this.id}/${this.$api.stopLive}`;
      this.$http.post(url, {}, url).then((res) => {
        if (res.data.success) {
          this.$message({
            message: '操作成功',
            type: 'success',
          });
          this.isStopDialogVisible = false;
          this.isPlay = true;
          this.player.dispose();
          this.getDetail();
        } else {
          this.$message({
            message: res.data.message,
            type: 'error',
          });
        }
      });
    },

    // 是否禁用
    disabled() {
      let startTime = new Date(this.info.start_time);
      let nowTime = new Date();
      this.isDisabled = startTime > nowTime || (this.info.live_status !== 2 && this.info.live_status !== 1);
    },
    // 状态确认
    putTagStatus() {
      let url = `${this.$api.playback}/${this.video_id}/${this.$api.showType}`;
      this.$http.post(url, {}, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.$message({
            message: '操作成功',
            type: 'success',
          });
          this.closeDiog();
          this.getDetail();
        }
      });
    },
    // 状态弹框关闭
    closeDiog() {
      this.isdelDialogVisible = false;
    },
    // 开始直播
    startLive(info) {
      if (info.live_status === 2) {
        let url = `${this.$api.meeting}/${this.id}/${this.$api.startLive}`;
        this.$http.post(url, {}, url).then((res) => {
          if (res.data.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.getDetail();
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
      } else {
        this.isStopDialogVisible = true;
      }
    },
    // 播放
    palyLive() {
      this.init();
    },
    // 初始化
    init() {
      this.isPlay = !this.isPlay;
      this.player = TCPlayer(this.player_id, {
        controls: true,
        muted: true,
        controlBar: {
          progressControl: false,
          volumePanel: true,
          playbackRateMenuButton: false,
          fullscreenToggle: false,
          playToggle: false,
          timeDivider: false,
          currentTimeDisplay: false,
          durationDisplay: false,
          progressControl: false,
        },
      });
      this.player.on('ended', this.ended); // 监听播放完结束
      this.player.on('error', this.error);
      this.player.src(this.play_address);
      this.player.play();
    },
    ended() {},
    error() {},
    // 成功回调
    uploadStatus(type, key, file) {
      this.AddVideoTitle = file.name;
      let app_id = process.env.VUE_APP_CLOUD_APP_ID;
      this.form.address = `https://${app_id}.vod2.myqcloud.com/${key}`;
      this.$message({
        message: '视频上传成功',
        type: 'success',
      });
    },
    // 下拉
    contentStatus(command, item, index) {
      this.video_id = item.id;
      if (command === '1') {
        this.$util.downloadRes(item.address, `回放片段${index + 1}`);
      } else if (command === '2') {
        this.isAdd = false;
        this.dialogFormReplay = true;
        this.dialogTitle = '编辑回放';
        this.form.address = item.address;
        if (index === 0) {
          this.isFirst = false;
          this.form.pid = 0;
        } else {
          this.isFirst = true;
          this.form.pid = this.replayList[index - 1].id;
        }
        if (item.name !== '') {
          this.form.name = item.name;
          this.AddVideoTitle = `${item.name}.mp4`;
        } else {
          this.form.name = `回放片段${index + 1}`;
          this.AddVideoTitle = `回放片段${index + 1}.mp4`;
        }
      } else if (command === '3') {
        console.log(item.id);
        this.showOrHiden = '显示';
        this.isdelDialogVisible = true;
      } else if (command === '4') {
        this.showOrHiden = '隐藏';
        this.isdelDialogVisible = true;
      }
    },

    openPage() {
      let host = window.location.host;
      let protocol = window.location.protocol;
      let url = `${protocol}//${host}/${this.$api.meeting}/components/${this.video_uuid}`;
      window.open(url);
    },
    // 评论内容
    msgContent(val) {
      if (val.MsgType === 'TIMTextElem') {
        return val.MsgContent.Text;
      }
    },
    // 获取评论
    getNews() {
      this.loading = true;
      let url = `${this.$api.meeting}/${this.id}/${this.$api.news}?page=${this.page}&pageSize=${this.pageSize}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.comment = this.comment.concat(res.data.data.data);
          this.commentNum = res.data.data.meta.total;
        }
      });
    },
    // 获取详情
    getDetail() {
      let url = `${this.$api.meeting}/${this.id}`;
      this.loading = true;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.info = res.data.data;
          this.disabled();
          if (this.info.live_status === 1 && this.info.type === 'other') {
            setTimeout(() => {
              this.init();
            }, 500);
          }
          this.play_address = this.info.play_address;
          this.tableList = this.info.playback ? this.info.playback : [];
          console.log(this.tableList, 'llll');
          this.video_uuid = res.data.data.uuid;
          if (this.open) {
            this.renderList = this.tableList;
            return;
          }
          if (this.info.playback && this.info.playback.length > 4) {
            this.renderList = this.info.playback.slice(0, 4);
          } else if (this.info.playback && (this.info.playback.length < 4 || this.info.playback.length === 4)) {
            this.renderList = this.info.playback ? this.info.playback : [];
          }
        }
      });
    },
    // tab切换
    handleClick(tab) {
      if (tab.name === 'live') {
        // if (this.player) {
        //   this.player.dispose();
        // }
        this.$router.push({
          path: '/meeting',
        });
      }
    },
    //加载更多
    commentMore() {
      this.page += 1;
      this.getNews();
    },
    // 复制链接
    delImg(url) {
      const save = function (e) {
        e.clipboardData.setData('text/plain', url);
        e.preventDefault(); // 阻止默认行为
      };
      document.addEventListener('copy', save); // 添加一个copy事件
      document.execCommand('copy'); // 执行copy方法
      this.$message({ message: '复制成功', type: 'success' });
    },
    listType() {
      this.open = !this.open;
      if (this.open) {
        this.renderList = this.tableList;
      } else {
        this.renderList = this.tableList.slice(0, 4);
      }
    },
    handleCloseVideo() {
      this.isvideoDialogVisible = false;
    },
    handleCloseCom() {
      this.comDialogVisible = false;
      this.commentList = [];
      this.page = 1;
      this.getNews();
    },
    palyVideo(item, index) {
      // if (item.media !== null && item.media.content.m3u8) {
      //   this.media_id = item.media.content.m3u8.high;
      // } else {
      this.media_id = item.address;
      // }
      this.isvideoDialogVisible = true;
      this.videoTitle = item.name ? item.name : `回放片段${index + 1}`;
    },
  },

  components: {
    EmptyPrompt,
    PlayVideo,
    uploadVideo,
  },
};
</script>
<style lang="less" scoped>
@import '~assets/less/meeting/show.less';
</style>
